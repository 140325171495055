import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServicePagePortal from '../../components/service-page-portal';

class FeaturedService extends Component {
    render() {
        return (
            <Layout>
                <div className="featured-service-page">
                    <HelmetEx>
                        <div metadata="title">In  | Interior Design Consultation | Elmwood Park NJ</div>
                        <div metadata="keywords">Interior,services,design,consultation,bergen county,NJ</div>
                        <div metadata="description">
                            Count on our professional staff for the finest in 
                            interior design service - to suit any decorating 
                            style and any size project
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>FEATURED SERVICES</h1>
                                            <h3>Count on our professional staff for the finest in interior design service</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <ServicePagePortal activePath={"/services/featured-services"}>
                                <section>
                                    <h4>INTERIOR DESIGN CONSULTATION</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Count on our professional staff for the finest in interior design service -
                                        to suit any decorating style and any size project
                                    </p>
                                </section>
                                <section>
                                    <h4>THE HUNTER DOUGLAS LIFETIME GUARANTEE</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Hunter Douglas provides a Lifetime Guarantee on all its
                                        window covering products!
                                    </p>
                                    <p>
                                        Should you not be thoroughly satisfied, simply contact Hunter Douglas
                                        Inc. at 1-888-501-8364 or visit hunterdouglas.com
                                    </p>
                                    <p>
                                        Here are more details about the hunter Douglas Lifetime Guarantee:
                                    </p>
                                </section>
                                <section>
                                    <h3>Covered by a Lifetime Limited Warranty</h3>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Hunter Douglas window fashion products are covered for defects in materials,
                                        workmanship or failure to operate for as long as the original retail purchaser owns the product
                                        (unless shorter periods are provided below).
                                    </p>
                                    <p>Coverage extends to</p>
                                    <ul className="pl-0 bullets">
                                        <li>
                                            All internal mechanisms,
                                        </li>
                                        <li>
                                            Components and brackets,
                                        </li>
                                        <li>
                                            Fabric delamination, and
                                        </li>
                                        <li>
                                            Operational cords for a full 7 years from the date of purchase.
                                        </li>
                                    </ul>
                                    <p>
                                        Repairs and/or replacements will be made with like or similar parts or products.
                                        Hunter Douglas motorization components are covered for 5 years from the date of purchase.
                                    </p>
                                    <h3>Not Covered by a Lifetime Limited Warranty</h3>
                                    <p>The following is not covered:</p>
                                    <ul className="pl-0 bullets">
                                        <li>Any conditions caused by normal wear and tear;</li>
                                        <li>Abuse, accidents, misuse or alterations to the product;</li>
                                        <li>Exposure to the elements(sun damage,wind,water/moisture) and discoloration or fading over</li>
                                        <li>Failure to follow our instuctions with respect to measurement, proper installation, cleaning or maintenance;</li>
                                        <li>Shipping charges, cost of removal and reinstallation.</li>
                                    </ul>
                                    <p>
                                        Hunter Douglas (or its licensed fabricator/distributor) will repair or replace the window fashion
                                        product or components found to be defective.
                                    </p>
                                </section>
                            </ServicePagePortal>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default FeaturedService;